<template>
    <el-row>
        <!-- 多云管理平台-->
        <fixed-item></fixed-item>

        <div class="bpm-header">
            <div class="header-title1">伊登云·跨平台</div>
            <div class="header-title2">eBPM审批管理系统</div>
<!--            <div class="header-btn">-->
<!--                <div class="btn-text">-->
<!--                    在线咨询-->
<!--                    <img src="../../img/product/bpm/icon1.svg">-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <div class="bpm-content">
            <div class="content1-img">
                <div>
                    <img src="../../img/product/bpm/content_img1.png">
                </div>
                <p class="img1-text">eBPM审批管理系统是一套基于云端的业务流程构建和管理平台，为用户提供灵活的拖拽式设计界面，帮助企业无代码化快速构建企业内部业务流程，实现跨部门的高效业务流程审批和管理, 为不同企业发展阶段提供不同的流程自动化支撑。</p>
            </div>

            <div class="content2-module">
                <div class="module-text1">您的企业为什么需要它</div>
                <p class="module-text2"> 通过eBPM审批管理解决方案，让您在Teams中统一完成流程构建，在线申请， 任务审批等工作。</p>
<!--                <div class="module-btn">-->
<!--                    <div class="btn-text">-->
<!--                        在线咨询-->
<!--                        <img src="../../img/product/cloudManage/icon2.svg">-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="content5-list">
                <div class="first-item">
                    <div class="item-left">
                        <img src="../../img/product/bpm/content_img2.png">
                    </div>
                    <div class="item-right">
                        <div class="right-title">丰富的表单控件</div>
                        <div class="right-content">提供多种丰富的表单控件，轻松满足各种场景的业务需要。并支持多设备运行，只需一次表单配置，即可在不同设备上进行使用。</div>
                    </div>
                </div>
                <div class="second-background">
                    <div class="second-item">
                        <div class="item-left">
                            <div class="left-title">图形化表单设计</div>
                            <div class="left-content">提供可视化图形表单设计器，只需简单拖拽即可配置多种业务场景的流程表单，并利用丰富的表单控件，满足企业内部各种复杂业务表单的构建需求。</div>
                        </div>
                        <div class="item-right">
                            <img src="../../img/product/bpm/content_img3.png">
                        </div>
                    </div>
                </div>
                <div class="first-item">
                    <div class="item-left">
                        <img src="../../img/product/bpm/content_img4.png">
                    </div>
                    <div class="item-right">
                        <div class="right-title">图形化流程设计</div>
                        <div class="right-content">提供可视化图形化流程设计器，只需简单拖拽即可配置多种分支条件的业务审批路径。支持任务审批，变量设置，及业务数据交互等多种节点类型等。支持基于组织和角色的审批人设定，支持同意、拒绝、撤销、回退，重新发起等多种行为控制等。</div>
                    </div>
                </div>
                <div class="second-background">
                    <div class="second-item">
                        <div class="item-left">
                            <div class="left-title">支持移动端</div>
                            <div class="left-content">eBPM可以在移动端快速发起审批流程，审批，查看流程等操作。</div>
                        </div>
                        <div class="item-right">
                            <img src="../../img/product/bpm/content_img5.png">
                        </div>
                    </div>
                </div>
            </div>

            <div class="content6-service">
                <div class="service-title">完美的售后服务</div>
                <div class="service-items">
                    <div class="service-item">
                        <div class="item-text">本产品由深圳伊登软件有限公司（销售主体）为您优选，并提供服务和发票，由阿里云授权华安视讯科技提供产品技术支持和运维服务</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">在质保期内若产品制造质量出现问题，享受7日内退货，15日内换货，15日以上在质保期内享受免费保修等三包服务</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">正常的设备维护保养工作由用户解决，随时提供远程指导</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">在质保期内，我公司对客户由于使用不当或者人为原因造成材料、设备的损坏，免费保修，只收材料、设备成本费。</div>
                    </div>
                </div>
            </div>

            <div class="content7-footer">
<!--                <div class="footer-try">在线试用</div>-->
            </div>
        </div>
    </el-row>
</template>

<style scoped lang="scss">
* {
    font-size: 14px;
}
.bpm-header {
    .header-title1 {
        font-size: 48px;
        font-weight: 600;
        color: #4050B5;
        text-align: center;
        margin-top: 3%;
    }
    .header-title2 {
        font-size: 30px;
        font-weight: 400;
        color: #4050B5;
        text-align: center;
        margin-top: 1.5%;
    }
    .header-btn {
        width: 10%;
        height: 44px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 22px;
        border: 1px solid #4050B5;
        margin: 1.5% auto 0%;
        cursor: pointer;
        .btn-text {
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #4050B5;
        }
    }
}

.bpm-content {
    background: url("../../img/product/bpm/bg_1.png") no-repeat top center;
    margin-top: 8%;
    .content1-img {
        text-align: center;
        width: 40%;
        margin: -6% auto 0%;
        .img1-text {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 28px;
        }
    }
    .content2-module {
        width: 44%;
        margin: 6% auto 0%;
        .module-text1 {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
        }
        .module-text2 {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5%;
            line-height: 28px;
        }
        .module-btn {
            width: 22%;
            height: 44px;
            display: flex;
            align-items: center;
            border-radius: 22px;
            border: 1px solid #FFFFFF;
            margin: 5% auto 0%;
            cursor: pointer;
            .btn-text {
                width: 100%;
                text-align: center;
                font-weight: 500;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }
    .content3-img {
        text-align: center;
        margin-top: 5%;
    }
    .content4-module {
        width: 40%;
        margin: 5% auto 0%;
        .module-text1 {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
        }
        .module-text2 {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5%;
            line-height: 28px;
        }
    }
    .content5-list {
        //width: 60%;
        margin: 2% 0% 2%;
        .first-item {
            display: flex;
            align-items: center;
            padding: 2.5% 0%;
            width: 60%;
            margin: auto;
            .item-left {
                width: 40%;
                img {
                    width: 100%;
                }
            }
            .item-right {
                width: 40%;
                margin-left: 16%;

                align-items: center;
                .right-title {
                    font-size: 30px;
                    font-weight: 500;
                    color: #FFFFFF;
                }
                .right-content {
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 33px;
                    margin-top: 8%;
                }
            }
        }
        .second-background {
            background: rgba(33,33,33,0.2000);
            .second-item {
                display: flex;
                align-items: center;
                width: 60%;
                margin: auto;
                .item-left {
                    width: 40%;
                    .left-title {
                        font-size: 30px;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                    .left-content {
                        font-size: 18px;
                        color: #FFFFFF;
                        line-height: 33px;
                        margin-top: 8%;
                    }
                }
                .item-right {
                    width: 50%;
                    margin-left: 10%;
                    padding-top: 3%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .content6-service {
        .service-title {
            text-align: center;
            font-size: 40px;
            font-weight: 500;
            color: #FFFFFF;
        }
        .service-items {
            display: flex;
            flex-wrap: wrap;
            width: 70%;
            margin: 3% auto;
            .service-item {
                padding: 9% 5%;
                margin: 1%;
                width: 38%;
                display: flex;
                align-items: center;
                background: rgba(33,33,33,0.2000);
                box-shadow: 0px 0px 17px 0px rgba(33,33,33,0.1000);
                .item-text {
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 28px;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    .content7-footer {
        background: url("../../img/product/cloudManage/bg_4.png") no-repeat center;
        height: 225px;
        display: flex;
        align-items: center;
        .footer-try {
            width: 10%;
            height: 20%;
            background: #FFFFFF;
            border-radius: 28px;
            display: flex;
            justify-content:center;
            align-items:center;
            margin: auto;
            cursor: pointer;
        }
    }
}
</style>

<script>
import FixedItem from "../../compoents/fixed/fixedItem";
export default {
    name: "productBPM",
    components: {FixedItem},
    // components: {fixedItem}
}
</script>